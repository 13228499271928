<template>
  <a-card :bordered='false'>
    <SmallSquare class="m-b-10" v-if='activeKey==1' content="1.查看品牌商短信按天统计的发送扣除情况
    2.短信发送默认统计昨天的发送扣除情况，当天的数据暂无法实时统计" />
    <SmallSquare class="m-b-10" v-else content="统计品牌端查看充值记录，实时记录" />
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane :key="1" tab="发送记录">
        <sendOut></sendOut>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="充值记录">
        <recharge></recharge>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>
<script>
  import { ref } from 'vue';
  import recharge from './components/recharge'
  import sendOut from './components/sendOut'
  export default {
    components: { recharge, sendOut },
    setup(props) {
      const activeKey = ref(1)
      return {
        activeKey
      }
    }
  }
</script>