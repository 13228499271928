<template>
  <a-form ref="form" :model="search" :style="{ marginBottom: '20px' }" :initialValues="search"
    :onFinish="pageSearchChange" layout="inline">
    <a-form-item label="品牌商" name="keyword">
      <a-input v-model:value="search.keyword" style="width: 250px" placeholder="请输入品牌商编号/公司名称"></a-input>
    </a-form-item>
    <a-form-item label="充值时间" name="">
      <a-range-picker valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" @change="onChange" />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" htmlType="submit">搜索</a-button>
    </a-form-item>
  </a-form>
  <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false" rowKey="id" :loading="listLoading"></a-table>
  <a-pagination :style="{ marginTop: '20px', textAlign: 'right' }" show-size-changer show-quick-jumper
    :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange"
    @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
</template>

<script>
  import { ref, onMounted, reactive, toRefs } from "vue";
  import { getRechargeRecordList } from "@/api/message";
  import { $iscode } from "@/utils/app";
  import { message } from "ant-design-vue";
  let defSearch = {
    keyword: "",
    page: 1,
    limit: 10,
    start_time: "",
    end_time: "",
  };
  export default {
    name: 'brandList',
    components: {},
    setup(props) {
      let search = ref({ ...defSearch });
      let total = ref(1);
      const state = reactive({
        dataSource: [],
        listLoading: false,
        columns: [
          {
            title: "品牌商编号",
            dataIndex: "brand_id"
          },
          {
            title: "公司名称",
            customRender: ({ record }) => {
              return record.brand_name ? record.brand_name : '-'
            }
          },
          {
            title: "充值金额",
            dataIndex: "amount",
          },
          {
            title: "充值数",
            dataIndex: "strip",
          },
          {
            title: "充值时间",
            dataIndex: "pay_time",
            width:180
          },
        ],
      });
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData(search.value);
      };
      //代理商列表
      const initData = async (values) => {
        state.listLoading = true;
        try {
          let res = await getRechargeRecordList(values).then((res) => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            state.dataSource = res.data.data;
            total.value = res.data.total;
          } else {
            message.error(res.message);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      const onChange = (e) => {
        if (e.length > 0) {
          search.value.start_time = e[0];
          search.value.end_time = e[1];
        } else {
          search.value.start_time = "";
          search.value.end_time = "";
        }
      };
      onMounted(() => {
        initData(search.value);
      });
      return {
        ...toRefs(state),
        initData,
        search,
        total,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        onChange,
      };
    },
  };
</script>